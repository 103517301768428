@import '../../../../scss/theme-bootstrap';

.site-header-formatter {
  height: $gnav-mobile-height;
  position: relative;
  @include breakpoint($landscape-up) {
    height: $gnav-height;
  }
  .gnav-offers-hidden &,
  &.site-header-formatter--sticky {
    height: $gnav-mobile-height;
    @include breakpoint($landscape-up) {
      height: $gnav-height;
    }
  }
  &.has-quick-links,
  .gnav-offers-hidden &.has-quick-links,
  &.site-header-formatter--sticky.has-quick-links {
    height: $gnav-mobile-height + $second-navbar-mobile-height;
    @include breakpoint($landscape-up) {
      height: $gnav-height;
    }
  }
  &__inner {
    @include transition(transform 0.25s ease);
    background-color: $color-white;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: $gnav-fixed-z-index;
    .site-header-formatter--sticky & {
      z-index: $gnav-fixed-z-index + 1;
    }
    // Hide the sticky Nav when scrolling down.
    :not(.active-utility-overlay) .site-header-formatter--hidden.site-header-formatter--sticky & {
      @include transform(translateY(-100%));
    }
    .gnav-util-overlay-active & {
      @include breakpoint($landscape-up) {
        z-index: $gnav-fixed-z-index + 1;
      }
      &.site-header-formatter--sticky {
        @include breakpoint($landscape-up) {
          z-index: $gnav-fixed-z-index + 1;
        }
      }
    }
  }
  &__inner-content {
    @include breakpoint($landscape-up) {
      max-width: $gnav-max-width;
      margin: 0 auto;
      position: relative;
    }
  }
  &__top {
    @include transition(height 0.25s ease, opacity 0.25s ease);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    opacity: 1;
    background-color: $color-black;
    .header-offers-banner-hidden &,
    .site-header-formatter--sticky & {
      height: 0;
      opacity: 0;
    }
  }
  &__top-utilities {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
    }
  }
  &__offers {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    @include breakpoint($landscape-up) {
      width: auto;
    }
  }
  &__header {
    padding: 0 $container-pad-small;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__bar {
    align-items: center;
    display: flex;
    position: relative;
    flex-flow: row wrap;
    justify-content: space-between;
    @include breakpoint($landscape-up) {
      padding-top: 28px;
    }
  }
  &__logo {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      position: relative;
      margin-#{$rdirection}: 0;
      margin-#{$ldirection}: $container-pad-landscape;
      order: 1;
    }
    svg {
      @include breakpoint($landscape-up) {
        width: 170px;
        height: 95px;
        text-decoration: none;
      }
    }
  }
  &__mobile-logo {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint($landscape-up) {
      display: none;
    }
    svg {
      margin: 0;
      width: 146px;
      height: 28px;
    }
  }
  // Mobile menu trigger (Hamburger).
  &__menu-icon {
    cursor: pointer;
    clear: none;
    @include breakpoint($landscape-up) {
      display: none;
    }
    svg {
      height: 24px;
      width: 20px;
    }
    label {
      display: block;
    }
  }
  // Only displays for mobile, takes user back to main sections list.
  &__mobile-menu-header {
    cursor: pointer;
    display: none;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    span {
      display: inline-block;
      font-size: 12px;
    }
    &-label {
      display: block;
      padding-#{$ldirection}: $gnav-mobile-hpad--outer;
      color: $color-white;
      .icon {
        fill: $color-white;
      }
    }
    .header-nav-section__trigger:checked ~ & {
      display: block;
      font-weight: 700;
      font-size: 15px;
      font-family: $font--heading;
      height: $gnav-mobile-link-height;
      line-height: $gnav-mobile-link-height;
      margin: 0;
      position: fixed;
      top: 0;
      width: 100%;
      background-color: $color-black;
      z-index: 1;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__links {
    @include breakpoint($landscape-up) {
      display: none;
    }
    &-link {
      font-size: 14px;
      height: 30px;
      line-height: 2.2;
      padding-#{$ldirection}: $gnav-mobile-hpad--outer;
      a {
        color: $color-white;
        text-decoration: none;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  &__links-container {
    @include breakpoint($landscape-up) {
      display: none;
    }
    padding-top: 15px;
  }
  &__background {
    background-color: $color-lighter-gray-2;
    height: 0;
    #{$ldirection}: 50%;
    opacity: 0;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    visibility: hidden;
    max-width: $max-width-cr21;
    width: 100%;
    z-index: $gnav-fixed-z-index - 1;
    @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
    @include breakpoint($landscape-up) {
      top: $gnav-height;
    }
    .toolbar & {
      @include breakpoint($landscape-up) {
        top: $gnav-height + $toolbar-height;
      }
    }
    .site-header-formatter__trigger-reset:checked ~ .site-header-formatter__sections & {
      @include breakpoint($landscape-up) {
        opacity: 0;
        visibility: hidden;
      }
    }
    .header-nav-section__trigger:checked ~ & {
      @include breakpoint($landscape-up) {
        height: $gnav-content-max-height;
        opacity: 1;
        visibility: visible;
      }
    }
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $utility-nav-height + $navbar-height;
      }
    }
    .toolbar.site-header-formatter--sticky &,
    .toolbar.gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $utility-nav-height + $navbar-height + $toolbar-height;
      }
    }
  }
  &__sections {
    display: none;
    @include breakpoint($landscape-up) {
      padding: 0;
      #{$ldirection}: 0;
      margin-top: 0;
      display: block;
      position: relative;
      width: 100%;
      height: $navbar-height;
    }
    .site-header-formatter__mobile-trigger:not(:checked) ~ & {
      display: none;
      @include breakpoint($landscape-up) {
        display: flex;
        justify-content: space-between;
        flex: 1 100%;
        order: 3;
        padding: 0 $container-pad-landscape;
      }
    }
    .site-header-formatter__mobile-trigger:checked ~ & {
      padding-top: $gnav-mobile-link-height;
      background-color: $color-black;
      height: 100%;
      position: fixed;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      display: block;
      overflow-y: auto;
      z-index: $gnav-fixed-z-index;
      @include breakpoint($landscape-up) {
        position: relative;
        width: inherit;
        background-color: unset;
        padding: 0;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ & {
      padding: 0;
      @include breakpoint($landscape-up) {
        z-index: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow-y: inherit;
        position: relative;
        top: auto;
        padding: 0 $container-pad-landscape;
      }
    }
  }
  &__mobile-menu-icon {
    display: inline-block;
    fill: $color-white;
    opacity: 1;
    position: relative;
    width: $gnav-icon--inline;
    height: $gnav-icon--inline;
    @include breakpoint($landscape-up) {
      background: none;
      display: none;
    }
    &:hover,
    &:active {
      fill: $color-black;
    }
  }
  &--close-icon {
    &,
    input[type='radio'] ~ & {
      position: fixed;
      top: 15px;
      #{$rdirection}: 20px;
      padding: 0;
      z-index: 2;
      @include breakpoint($landscape-up) {
        display: none;
      }
      &::before {
        display: none;
      }
      .icon {
        width: 15px;
        height: 15px;
        fill: $color-white;
      }
    }
    input[type='radio']:checked ~ & {
      &::after {
        display: none;
      }
    }
  }

  // Utility styles
  &__utilities {
    clear: none;
    order: 2;
    gap: 15px;
    display: flex;
    @include breakpoint($landscape-up) {
      gap: 20px;
      height: 95px;
      padding-#{$rdirection}: $container-pad-landscape;
    }
    &-item {
      position: relative;
      &.gnav-utility-item--active {
        background: $color-white;
      }
      .gnav-utility-item-overlay {
        h3 {
          font-size: 20px;
          line-height: 2.5;
          text-align: $ldirection;
          text-transform: uppercase;
          font-family: $font--helvetica-neue;
          font-weight: 700;
          p {
            margin: 0;
            line-height: 2.5;
          }
        }
        input[type='checkbox'] {
          float: $ldirection;
          + label {
            padding-#{$ldirection}: 20px;
            display: inline-block;
            margin-top: -17px;
          }
        }
        input[type='submit'] {
          height: 50px;
          min-width: 150px;
          font-size: 18px;
          font-family: $font--helvetica-neue;
        }
        .signin-block {
          padding: 0;
          &__main {
            padding: 0;
          }
          &__forgot-password {
            font-size: 14px;
            color: $color-blue;
            &:hover {
              color: $color-black;
            }
          }
          &__toggle {
            padding: 0 0 15px;
            font-size: 14px;
            &__copy {
              font-weight: 700;
            }
          }
          &__field {
            margin-bottom: 20px;
          }
          .social-login {
            &__container {
              padding-top: 0;
            }
            &__divider {
              margin-top: 0;
            }
            &__email-opt-in {
              @include swap_direction(padding, 25px 0 0);
              position: relative;
              label {
                @include swap_direction(padding, 0 40px 0 20px);
                display: inline-block;
                margin-top: -17px;
              }
              input[type='checkbox'] {
                float: $ldirection;
              }
            }
            &__legal {
              padding: 15px 0;
            }
            .fb-overlay-container {
              text-align: $ldirection;
            }
          }
        }
      }
      .site-email-signup__subtitle {
        text-align: $ldirection;
        font-style: normal;
        font-weight: 700;
        margin-top: -12px;
        text-transform: uppercase;
      }
      &-link {
        color: $color-white;
        cursor: pointer;
        display: flex;
        align-items: center;
        line-height: 1.4;
        letter-spacing: normal;
        text-decoration: none;
        padding: 0 15px;
        border: 2px solid black;
        background: none;
        height: $offerbar-height;
        min-height: $offerbar-height;
        font-family: $font--helvetica-neue;
        font-size: 15px;
        text-transform: uppercase;
        transition: none;
        .gnav-utility-item--active & {
          color: $color-black;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    .gnav-util {
      &__content {
        display: none;
        position: absolute;
        overflow-x: hidden;
        #{$rdirection}: 0;
        background: $white;
        border: 1px solid $color-primary-400;
        padding: 0 15px;
        z-index: 999;
        @include breakpoint($landscape-up) {
          #{$rdirection}: -#{$container-pad-landscape};
          position: absolute;
          width: 350px;
          top: 30px;
        }
        .site-header-formatter--sticky &,
        .gnav-offers-hidden & {
          top: $navbar-mobile-height;
          @include breakpoint($landscape-up) {
            top: 30px;
          }
        }
        &-header-title {
          font-size: 13px;
          color: $color-primary-700;
          font-family: $font--helvetica-neue-bold;
          padding: 0 0 20px;
          order: 1;
        }
        &-header {
          padding: 10px 0 0;
          display: flex;
          justify-content: space-between;
          &-close {
            order: 2;
            .icon--close {
              width: 15px;
              height: 15px;
              margin: 5px;
              display: inline-block;
            }
          }
          .cart-loading & {
            padding: 10px 0 15px;
          }
        }
      }
      &-nav-section-toggle {
        display: inline-block;
      }
      &__icon {
        color: $black;
        line-height: $navbar-mobile-height;
        position: relative;
        text-decoration: none;
        display: block;
        @include breakpoint($landscape-up) {
          display: flex;
        }
        .icon {
          width: 25px;
          height: 25px;
          &:before {
            font-size: 30px;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
      &-trigger:checked ~ .gnav-util__content {
        display: block;
        @include breakpoint($landscape-up) {
          margin-#{$rdirection}: $container-pad-landscape;
        }
      }
      &-trigger-label {
        padding: 0;
        &:before,
        &:after {
          display: none;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
    .contextual-links-region {
      position: initial;
    }
    // @todo Add styles for email sign up and search in this section
  }
  &__second-bar {
    width: 100%;
    height: $second-navbar-mobile-height;
    overflow: hidden;
    font-size: 16px;
    padding-top: 10px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__quick-links {
    height: $second-navbar-mobile-height;
    padding-#{$rdirection}: 5px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    &.text-cta {
      color: $color-black;
      font-family: $font--helvetica-neue-bold;
    }
  }
  &__account {
    .gnav-utility-item-overlay {
      display: block;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  &__email-signup {
    display: none;
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
  }
  &__account,
  &__email-signup,
  &__links-account {
    .elc-user-state-logged-in & {
      display: none;
    }
  }
  &__myaccount,
  &__signout,
  &__links-my-account,
  &__links-signout {
    display: none;
    .elc-user-state-logged-in & {
      display: block;
    }
  }
  // Shared overlay for the gnav and utility
  &-overlay {
    &,
    input[type='radio'] ~ & {
      background-color: $color-gray;
      cursor: pointer;
      display: none;
      height: 0;
      #{$ldirection}: 0;
      opacity: 0;
      position: fixed;
      top: 0;
      transition: visibility 0.3s, opacity 0.3s;
      visibility: hidden;
      width: 100%;
      z-index: $gnav-fixed-z-index - 2;
      &::after,
      &::before {
        display: none;
        visibility: hidden;
      }
    }
    .header-nav-section__trigger:checked + .header-nav-section__section + &,
    .gnav-util-trigger:checked + & {
      height: 100%;
      opacity: 0.75;
      visibility: visible;
      @include breakpoint($landscape-up) {
        display: block;
        top: $gnav-height;
      }
      .toolbar & {
        @include breakpoint($landscape-up) {
          top: $gnav-height + $toolbar-height;
        }
      }
      .site-header-formatter--sticky:not(.toolbar) & {
        @include breakpoint($landscape-up) {
          top: $utility-nav-height + $navbar-height;
        }
      }
      .toolbar.site-header-formatter--sticky & {
        @include breakpoint($landscape-up) {
          top: $utility-nav-height + $navbar-height + $toolbar-height;
        }
      }
    }
    // Open dark overlay for mobile. The previous section works only for PC
    .gnav-util-trigger:checked + & {
      display: block;
      top: $gnav-mobile-height;
      padding: 0;
      @include breakpoint($landscape-up) {
        top: $gnav-height;
      }
      .site-header-formatter--sticky &,
      .gnav-offers-hidden & {
        @include breakpoint($landscape-up) {
          top: $navbar-height;
        }
      }
    }
  }
  input.no-js-element[type='radio'],
  input.no-js-element[type='checkbox'] {
    position: absolute;
    left: -9999px;
  }
}

// Overrides for specific sections and states applied to html and body.
html.active-gnav {
  overflow: hidden; // No scrolling the body when fixed nav sections are active.
  overflow-y: hidden; // ios safari.
  @include breakpoint($landscape-up) {
    overflow-y: auto;
  }
  .site-header-formatter--hidden.site-header-formatter--sticky .site-header-formatter__inner {
    @include transform(unset);
  }
}

body {
  .active-gnav & {
    max-width: 100%;
    overflow: hidden; // No scrolling the body when fixed nav sections are active.
    overflow-y: hidden; // ios safari.
    width: 100%;
    position: relative;
  }
}
// Minimal nav class applied via elc_gnav_minimal_v1 for drupal.
// Class is on body for drupal, may be on html for pg pages.
.elc-gnav-minimal {
  .site-header-formatter__top,
  .site-header-formatter__menu-icon,
  .site-header-formatter__utilities,
  .site-header-formatter__header .site-header-formatter__sections {
    display: none;
  }
  .site-header-formatter__sections .site-header-formatter__mobile-trigger:not(:checked) ~ & {
    @include breakpoint($landscape-up) {
      .elc-gnav-minimal & {
        display: none;
      }
    }
  }
}
